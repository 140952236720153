@font-face {
  font-family: 'Sequel Sans';
  src: url('./fonts/sequel-sans-regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Sequel Sans';
  src: url('./fonts/sequel-sans-medium.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Sequel Sans';
  src: url('./fonts/sequel-sans-bold.ttf');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Sequel Black';
  src: url('./fonts/sequel-black-85.ttf');
  font-style: bold;
  font-weight: 400;
  font-display: swap;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
body {
  color: #ffffff;
  margin: 0;
  font-family: 'Sequel Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  touch-action: pan-y;
}

a {
  color: inherit;
}

button {
  font-family: 'Sequel Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#portal-root {
  position: absolute;
  z-index: 101;
}

input {
  font-family: 'Sequel Sans';
}
